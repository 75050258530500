import React from 'react';
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import apiClient from "../http-common";
// import {notifications, Notifications} from "./Request/Notifications";
import {Histories} from './Request/Histories';

const Header = () => {
    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }

    const postlogout = () =>{
      localStorage.removeItem('delox-user');
      localStorage.removeItem('user-type');
      localStorage.removeItem('binaryfair-account-histories');
      localStorage.removeItem('binaryfair-wallet-balances'); 
      setTimeout(() => {
          window.location.href = '/';
      }, 3000);
              
  }
  
    
    
  return (
    <div className="pt-1 mb-4 fixed-top shadow-sm bg-theme">
        <div className="page-title d-flex">
            <div className="align-self-center me-auto">
                <Histories/>
            </div>
            <div className="align-self-center ms-auto d-md-none">
                <Link to="#"
                data-bs-toggle="offcanvas" id="footer-toggle" data-bs-target="#menu-sidebar">
                    <i translate="no" className="material-icons-sharp color-theme" style={{fontSize: "28px", marginTop: "12px"}}>menu</i>
                </Link>
            </div>
        </div>
    </div>
  )
}
export default Header